import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'
import '../components/simplePageTemplate.scss'

export default function simplePageTemplate({ data }) {

  const { allWpMenu,
    wpPage: { translations, language, title, content },
    allWp: { nodes: [optionsPage] } } = data



  return (
    <>
      <Seo title={title} lang={language.locale} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <div className="content-wrapper">
        <div className="simple-page-template">
          <h1>{title}</h1>
          <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
      <CookiePopUp language={language} />
    </>
  )
}

simplePageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export const simplePageTemplateQuery = graphql`
  query simplePageTemplate($id: String!) {
    wpPage(id: {eq: $id}) {
      content
      title 
      translations {
        uri
      }
      language {
        locale
      }
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
        ...getOptionsPageSideBannerData
      }
    }
  } 
`